<template>
    <Portal selector="body">
        <div :id="isOpen ? 'modal-wrapper-active' : ''" ref="modalWrapper" class="base-modal">
            {{/* the modal itself */}}
            <Transition name="translate">
                <div v-show="isOpen" class="fixed w-full h-full top-0 left-0 nicescroll overflow-y-auto z-20 lg:z-45">
                    {{/* modal background */}}
                    <div
                        class="fixed top-0 left-0 w-full h-full bg-black opacity-80 z-60"
                        :class="{ 'pointer-events-none': arePointerEventsDisabledOnBgOverlay }"
                        @click="closeModal"
                    ></div>
                    <div class="scroll-container__inner" :class="{ 'grid grid-cols-12 gap-5': !autoWidth }">
                        <div class="h-4 col-span-12"></div>
                        <div :class="{ 'flex justify-center': autoWidth }">
                            <div
                                class="bg-white relative overflow-visible pointer-events-auto base-responsive-rounding"
                                :class="{
                                    'col-span-12 col-start-1 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 xl:col-span-6 xl:col-start-4':
                                        !autoWidth,
                                }"
                            >
                                {{/* close button is added like this because the prime vue button icon was always off-center :( */}}
                                <button class="close-button" @click="closeModal">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <path
                                            d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                                        ></path>
                                    </svg>
                                </button>
                                <div
                                    id="modalcontent"
                                    ref="modalContent"
                                    class="modal-content py-6 h-auto px-4 sm:px-14 lg:pt-14"
                                >
                                    <slot></slot>
                                </div>
                            </div>
                        </div>

                        <div class="h-4 col-span-12"></div>
                    </div>
                </div>
            </Transition>
        </div>
    </Portal>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from '../../static/scripts/body-scroll-lock.min';

export default {
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
        autoWidth: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            keyPressHandler: this.handleKeyPress,
            arePointerEventsDisabledOnBgOverlay: true,
        };
    },
    watch: {
        isOpen() {
            if (this.isOpen) {
                setTimeout(() => {
                    if (!this.$device.isIos) {
                        disableBodyScroll(this.$refs.modalWrapper);
                    }
                    this.arePointerEventsDisabledOnBgOverlay = false;
                }, 250);
            } else {
                enableBodyScroll(this.$refs.modalWrapper);
            }
        },
    },
    mounted() {
        window.addEventListener('keyup', this.keyPressHandler);
    },
    destroyed() {
        window.removeEventListener('keyup', this.keyPressHandler);
    },
    methods: {
        closeModal() {
            this.arePointerEventsDisabledOnBgOverlay = true;
            this.close();
        },
        handleKeyPress(e) {
            if (this.isOpen && e.key === 'Escape') {
                this.arePointerEventsDisabledOnBgOverlay = true;
                this.close();
            }
        },
    },
};
</script>

<style scoped>
.scroll-container__inner {
    @apply absolute w-full transition-all h-auto max-h-[100vh] pointer-events-none;
    @apply container left-1/2 bottom-1/2 translate-y-1/2 -translate-x-1/2;
}
.translate {
    &-enter-active,
    &-leave-active {
        transition: all 0.3s ease;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
        > .scroll-container__inner {
            transform: translate(-50%, calc(50% - 2rem));
        }
    }
}

.close-button {
    @apply rounded-full absolute w-6 h-6 top-6 right-6 bg-black z-1;
    transition: all 0.3s;

    svg {
        @apply absolute w-5 h-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

        path {
            fill: #fff;
            transition: fill 0.3s;
        }
    }

    @media (hover: hover) {
        &:hover {
            @apply bg-black;
        }
    }
}

.modal-content {
    max-height: unset;

    @media (max-width: 768px) {
        @apply container;
    }
}
</style>

<style>
.base-modal {
    h2 {
        @apply text-lg lg:text-xl;
    }
}
</style>
